import { Component, Inject, Renderer2 } from '@angular/core';

import { AnalyticsService, AuthService } from 'shared';
import { BaseComponent } from 'uikit';

import { filter } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends BaseComponent {
  constructor(
    @Inject(DOCUMENT) private _document,
    private _renderer: Renderer2,
    private _auth: AuthService, // Kickstart auth
    private _analytics: AnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this._renderer.addClass(this._document.body, 'onboarding-body');

    this._auth.user$
      .pipe(
        filter((u) => !!u?.uid),
        this.takeUntilDestroy
      )
      .subscribe((u) =>
        this._analytics.identify(u.uid, {
          createdAt: u?.metadata?.creationTime
        })
      );
  }
}
