import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'norby-arrow',
  templateUrl: './norby-arrow.component.html'
})
export class NorbyArrowComponent {
  @Input() color: string = '#140936';
}
